import React from 'react'

const Header = (props) => {
    return (
      <div className={props.styleName}>
      <h1 className="title title-middle header-title">{props.title}
        {props.else}
      </h1>
      
    </div>
    )
}

export default Header
