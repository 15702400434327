import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar/Sidebar';

const Целлюлоза = () => (
    <Layout>
      <Helmet>
          <title>Реагенты Бумажной Промышленности</title>
      </Helmet>
      <Header 
        styleName='header header-paper'
        title={<span className="text-header-title">Бумажная промышленность</span>}/>
      <div className="columns">
        <div className="column content">
          <div className="title-small-nomargintop title-small content-title">Реагенты для целлюлозно-бумажной промышленности и производства картона</div>          
          <div className="content-text">В сферу интересов UAB “Inžinerinė ekologija“ входит разработка высокотехнологичных решений и поставка качественных химических материалов и для технологических процессов в целлюлозно-бумажной промышленности:</div>
          <ul>
              <li className="content-text">Осаждение и осветление с применением синтетических коагулянтов и флокулянтов</li>
              <li className="content-text">Биоциды, консерванты и ПАВ для предотвращения развития биологии, процессов гниения и неприятных запахов, улучшения качества бумажной массы и обеспечение непрерывности работы бумагоделательной машины</li>
              <li className="content-text">Пеногасители, смачивающие средства и антискаланты</li>
              <li className="content-text">Моющие концентраты для очистки процессного оборудования</li>
              <li className="content-text">Дозирующее оборудование: насосы-дозаторы, автоматические дозирующие установки, в том числе для подачи коагулянтов и флокулянтов</li>
          </ul>
        </div>
        <Sidebar
        analysis='Анкеты'
        boiler='Бойлер'
        cooling='Охлаждение'
        ro='Осмос'
        broshure='IN-ECO брошура'
        certificates='Сертификаты'
        russian={true}
        />
      </div>
    </Layout>
)

export default Целлюлоза