import React from 'react'
import PDFfile from '../../images/pdf-file-image.png'
import Brochure from '../../images/brochure.png'
import ISO from '../../images/ISOTransparent.png'
import FullBrochure from '../../images/PDFs/Brochure_EN.pdf'
// import FullBrochureRu from '../../images/PDFs/Brochure_RU.pdf'
import RoForm from '../../images/PDFs/RO_form_EN.pdf'
import RoFormRu from '../../images/PDFs/RO_form_RU.pdf'
import BoilerForm from '../../images/PDFs/Boiler_form_EN.pdf'
import BoilerFormRu from '../../images/PDFs/Boiler_form_RU.pdf'
import CoolingForm from '../../images/PDFs/Cooling_form_EN.pdf'
import CoolingFormRu from '../../images/PDFs/Cooling_form_RU.pdf'

let boilerFormRu = null
let coolingFormRu = null
let roFormRu = null
let brochureRu = null

const Sidebar = props => {
  if (props.russian) {
    boilerFormRu = BoilerFormRu
    coolingFormRu = CoolingFormRu
    roFormRu = RoFormRu
    // brochureRu = FullBrochureRu
  } else {
    boilerFormRu = null
    coolingFormRu = null
    roFormRu = null
    // brochureRu = null;
  }
  return (
    <div className="column sidebar">
      <div className="sidebar-section">
        <div className="content-text">{props.analysis}</div>
        <div className="file">
          <div className="columns">
            <div className="column">
              <img src={PDFfile} className="file-image" alt="" />
            </div>
            <div className="column">
              <div className="file-row">
                <div className="file-title">{props.boiler}</div>
              </div>
              <div className="file-row">
                <a
                  className="file-button"
                  href={boilerFormRu || BoilerForm}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>View</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="file">
          <div className="columns">
            <div className="column">
              <img src={PDFfile} className="file-image" alt="" />
            </div>
            <div className="column">
              <div className="file-row">
                <div className="file-title">{props.cooling}</div>
              </div>
              <div className="file-row">
                <a
                  className="file-button"
                  href={coolingFormRu || CoolingForm}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>View</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="file">
          <div className="columns">
            <div className="column">
              <img src={PDFfile} className="file-image" alt="" />
            </div>
            <div className="column">
              <div className="file-row">
                <div className="file-title">{props.ro}</div>
              </div>
              <div className="file-row">
                <a
                  className="file-button"
                  href={roFormRu || RoForm}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>View</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar-section">
        <div className="content-text">{props.broshure}</div>
        <a
          href={brochureRu || FullBrochure}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Brochure} className="brochure-img" alt="" />
        </a>
      </div>
      <div className="sidebar-section">
        <div className="content-text">{props.certificates}</div>
        <div className="iso-img-container">
          <img
            src={ISO}
            style={{ width: '50%', marginRight: '19px' }}
            className="iso-img"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
